#vrtx-frontpage,
body {
  #footer-wrapper {
    background: var(--color-dark);
    #footers {
      min-height: 200px;
      overflow: visible;
      padding: 50px 20px;
      display: grid;
      grid-template-columns: 1fr 0.8fr;

      .footer-logo,
      .footer-logo img {
        display: block;
      }
      .menu-label {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .col-3-5 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 30px;
      }
      .col-2-5.responsible-login {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 30px;

        .column.login {
          .vrtx-login-manage-component.vrtx-dropdown-component.vrtx-dropdown-component-toggled {
            margin-top: 0;
            white-space: nowrap;
          }
        }
      }

      .vrtx-dropdown-component .vrtx-dropdown-wrapper {
        top: auto;
        right: 0;

        a {
          color: var(--textColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  #vrtx-frontpage,
  body {
    #footer-wrapper {
      #footers {
        .col-3-5,
        .col-2-5.responsible-login {
          gap: 0 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1130px) {
  #vrtx-frontpage,
  body {
    #footer-wrapper {
      #footers {
        min-height: 300px;
        grid-template-columns: 1fr 1fr;
        .col-3-5,
        .col-2-5.responsible-login {
          grid-template-columns: 1fr;
          gap: 30px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  html body.not-for-ansatte #head-wrapper #head #header {
    min-height: 106px;
    height: auto;
  }
  body #footer-wrapper #footers .col-2-5.responsible-login {
    .content .menu-label {
      margin: 0 0 10px 0;
    }
    .column.login .menu-label {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 800px) {
  #vrtx-frontpage,
  body {
    #footer-wrapper {
      #footers {
        grid-template-columns: 1fr;
        gap: 30px 0;

        .vrtx-dropdown-component .vrtx-dropdown-wrapper {
          right: auto;
          left: 0;
        }
      }
    }
  }
}