// css for external folders like stinn and studinn
@import "typography.less";
@import "grid/footer-subpages.less";

:root {
  --img-special-bg: url('/vrtx/dist/resources/images/stinn/wrinkled-bg.webp');
  --color-dark: #262626; // dark grey
  --color-button-hover: #FDF1F5;
  --color-stinn-light: #FDF1F5; //light pink
  --color-stinn-medium: #FF93B9; //medium-pink
  --color-stinn: #D60F56; //shock pink
  --color-stinn-hover: #A70C42;
  --color-neutral-light: #E6E7E8; //light grey
  --linkColor: (var(--linkColor));
  --logo-stinn: url(/vrtx/dist/resources/images/stinn/stinn-minimal-logo.svg);
}

html body #head-wrapper #head #header {
  a.logo {
    background-image: var(--logo-stinn);
    width: 108px;
    height: 75px;
  }
  #header-tools {
     #header-language a, button.toggle-search, .sidebar-menu-toggle {
      color: var(--textColor);
      &:hover, &:focus {
        color: var(--textColor);
        .textUnderlineHover(var(--textColor));
      }
    }
    #header-language span {
      border: 2px solid var(--textColor);
      color: var(--textColor);
    }
    button.toggle-search {
      background-image: var(--icon-magnifyer);
    }
    .sidebar-menu-toggle:after {
      background-image: var(--icon-menu);
    }
  }
}
body.en #head-wrapper #head #header a.logo {
  background-image: var(--logo-stinn);
}

html body.sidebar-menu-wrapper-visible #head-wrapper #head #header #header-tools .sidebar-menu-toggle:after {
  background-image: var(--icon-menu-close);
}

#vrtx-structured-event, #vrtx-structured-article, #vrtx-web-page, #vrtx-searchview {
  h1, h2 {
    color: var(--color-stinn);
  }
}
body#vrtx-frontpage h1, body h1, h1, #vrtx-structured-article h1, body#vrtx-frontpage h1 {
  font-family: "Josefin Sans", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

html .sidebar-services-language-menu li {
  display: none;
}
body .main ul.only-links li a:not(.button):not(.button-large) {
  &:hover,
  &:focus {
    &:before,
    &:after {
      background-image: var(--img-arrow-big2);
    }
  }
}

.vrtx-frontpage-box.vrtx-frontpage-box-picture {
  &.centered-introduction {
    .vrtx-box-content {
      flex: 1 100%;
      align-self: center;
      p {
        font-family: "Graphik";
        font-size: 2.5rem;
        line-height: 1.5;
        padding-right: 60px;
      }
    }
  }
  &.image-right,
  &.image-left {
    display: flex;
    flex-direction: row-reverse;
    .vrtx-frontpage-box-picture {
      flex: 1 100%;
      margin: 0;
      align-self: center;
    }
  }
  &.image-right {
    flex-direction: row-reverse;
    margin-left: 0;
  }
  &.image-left {
    &.centered-introduction {
      .vrtx-box-content {
        align-self: center;
        p {
          padding-right: 60px;
        }
      }
    }
    flex-direction: row;
  }
}

body.not-for-ansatte #center-menu-same-level-folders {
  ul.vrtx-breadcrumb-menu,
  .vrtx-subfolder-menu ul {
    ul.large-box {
      li a {
        min-height: 3em;
      }
    }
  }
}

.vrtx-button:not(form).stinn, 
.button.stinn, 
input[type="button"].stinn, 
input[type="submit"].stinn, 
input[type="reset"].stinn, 
.ui-dialog.ui-widget button.stinn, 
button.stinn {
    background-color: var(--color-stinn);
    &:hover, &:focus {
      background-color: var(--color-stinn-hover);
    }
}


#main .navigation-links ul li a,
.button.white {
  border: 1px solid var(--color-dark);
  background-color: transparent;
  color: var(--color-dark);
  &:not(.feedback-yes):not(.feedback-no):after {
    background-image: var(--img-arrow-big2);
  }
  &:hover,
  &:focus {
    background-color: var(--color-stinn-medium);
  }
}

.read-more, .read-more-small, .vrtx-more a {
  &:focus, &:hover {
    &:before {
      .arrow-big-bg-image();
      background-size: auto 0.6em;
    }
  }
}

.main ul.only-links {
  li {
    a:not(.item-title):not(.button):not(.button-large):not(.vrtx-button):not(.vrtx-field-contact) {
      &:hover, &:focus {
        &:before {
          .arrow-big-bg-image();
        }
      }
    }
  }
}

#vrtx-structured-event #vrtx-content .time-and-place-columns .column {
  border-color: var(--color-stinn-medium);
}

body #main {
  .grid-container:not(.row-all-colored) {
    .two-column-feed,
    .three-column-feed {
      .vrtx-event-component.with-images .vrtx-event-component-item,
      .vrtx-feed.with-images {
        .vrtx-list-item-content:only-child {
          background: var(--color-stinn-medium);
        }
      }
    }
  }
}

#vrtx-web-page .vrtx-date-info-with-content-manager {
  background-color: var(--color-neutral-light);
}

#center-menu-same-level-folders,
#main {
  #vrtx-semester-links li a,
  .navigation-links ul li a,
  .vrtx-subfolder-menu ul li a,
  ul.navigation-links li a,
  ul.vrtx-breadcrumb-menu li a {
    line-height: 1.5;
    color: var(--textColor);
    background-color: transparent;
    border: 1px solid #D60F56;
    &:focus, &:hover {
      background-color: var(--color-button-hover);
  }
  }
}

#main .row-all-colored, body.sidebar-menu-wrapper-visible .sidebar-menu-wrapper {
  background: var(--img-special-bg) no-repeat center top;
  background-size: cover;
}

body.sidebar-menu-wrapper-visible {
  padding-top: 0 !important;
 .sidebar-menu-wrapper {
    margin-top: 0;
    padding-top: 100px !important;
  }
  #head-wrapper {
    background: none;
  }
}
.sidebar-services-language-menu {
  border-top: none;
}
.vrtx-additional-search-info {
  display: none;
}

/* feeds articles and events */
#vrtx-frontpage #main {
  .grid-container {
    .two-column-feed,
    .third-box-left,
    .third-box-middle,
    .third-box-right,
    .three-column-feed {
      .vrtx-feed.with-images,
      .vrtx-event-component.with-images .vrtx-event-component-item {
        .vrtx-list-item-content:only-child {
          background: var(--color-stinn-medium);
        }
      }
    }
  }
}
/* feeds  */

#vrtx-frontpage,
body {
  #footer-wrapper {
    background: var(--color-dark);
    #footers {
      display: unset;
      background: none transparent;
      border-top: none;
      * { 
        color: white;
      }
      .footer-content {
        display: flex;
        flex-direction: row;
        gap: 20px 40px;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
        padding: 50px 20px;
        @media only screen and (max-width: 1205px) {
          justify-content: flex-start;
        }
        @media only screen and (max-width: 650px) {
          padding: 30px 20px;
        }
        .footer-logo img {
          max-width: 300px;
          width: 300px;
          min-width: 150px;
          object-fit: contain;
          margin-left: -8px;
        }
        .responsible-login {
          background-color: transparent;
          color: white;
          bottom: 0;
          right: 0;
          width: fit-content;
          margin: 0;
          .content {
            position: relative;
            background-color: transparent;
            width: fit-content;
            padding: 0;
            top: 0;
            right: 0;
            margin-left: auto;
            @media only screen and (max-width: 1205px) {
              margin: 0;
            }
            dl {
              display: grid;
              grid-template-rows: auto auto;
              grid-auto-columns: 1fr;
              grid-auto-flow: column;
              column-gap: 40px;
              max-width: 1050px;
              width: fit-content;
              margin: 0;
              @media only screen and (max-width: 650px) {
                grid-auto-flow: row;
              }
            }
          }
        }
        .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
          background-image: url(/vrtx/dist/resources/uio2/css/images/footer/dropdown.png);
          &:focus, &:hover {
            background-image: url(/vrtx/dist/resources/uio2/css/images/footer/dropdown-hover.png);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  html body.not-for-ansatte #head-wrapper #head #header {
    min-height: 106px;
    height: auto;
  }
}

@media only screen and (max-width: 580px) {
  html body #head-wrapper {
    #head #header #header-tools {
      a.sidebar-menu-toggle {
        justify-content: flex-start;
      }
    }
  }
  .vrtx-frontpage-box.vrtx-frontpage-box-picture {
    &.centered-introduction.image-right {
      flex-direction: column-reverse;
    }
  }
}

.arrow-big-bg-image {
  background-image: var(--img-arrow-big2);
}
